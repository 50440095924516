.serverService-banner {
  background: url(../assets/banners/Server\ and\ Storage\ Services1.jpg);
  background-size: cover;
  height: 700px;
}
.serverService-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 14% 22%;
  position: absolute;
  transform: translate(13%, 30%);
}
.serverStrorageIntro-image {
  background: url(../assets/banners/networksubhead2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.serverStrorageIntro-image2 {
  background: url(../assets/banners/serverIntro2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.serverStrorageIntro-image3 {
  background: url(../assets/banners/serverIntro5.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.serverStrorageIntro-image4 {
  background: url(../assets/banners/serverIntro4.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .serverService-style {
    font-size: 20px;
    padding: 26% 2%;
  }
  .serverService-banner {
    height: 300px;
  }
  .serverStrorageIntro-image3 {
    height: 300px;
  }
  .serverStrorageIntro-image4 {
    height: 300px;
  }
  .serverStrorageIntro-image2 {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .serverService-style {
    font-size: 22px;
    padding: 25% 7%;
  }
  .serverService-banner {
    height: 300px;
  }
  .serverService-banner {
    height: 350px;
  }
  .serverStrorageIntro-image4 {
    height: 350px;
  }
  .serverStrorageIntro-image2 {
    height: 350px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .serverService-style {
    font-size: 24px;
    padding: 21% 15%;
  }
  .serverService-banner {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .serverService-style {
    font-size: 30px;
    padding: 16% 20%;
  }
  .serverService-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .serverService-style {
    padding: 16% 16%;
  }
  .serverService-banner {
    height: 500px;
  }
}
