.virtualizationService-banner {
  background: url(../assets/banners/server.jpg);
  background-size: cover;
  height: 700px;
}
.virtualizationService-style {
  color: rgb(0, 0, 0);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 28% 34%;
}
.virtualizitionIntro-image {
  background: url(../assets/banners/virutualIntro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.virtualizitionIntro-image2{
  background: url(../assets/banners/virutualIntro2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.virtualizitionIntro-image3{
  background: url(../assets/banners/virutualIntro3.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.virtualizitionIntro-image4{
  background: url(../assets/banners/virutualIntro4.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.service-text-container2{
  padding: 5% 0;
}

@media (max-width: 400px) {
  .virtualizationService-style {
    font-size: 20px;
    padding: 40% 14%;
  }
  .virtualizationService-banner {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .virtualizationService-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .virtualizationService-banner {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .virtualizationService-style {
    font-size: 24px;
    padding: 30% 20%;
  }
  .virtualizationService-banner {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .virtualizationService-style {
    font-size: 30px;
    padding: 30% 26%;
  }
  .virtualizationService-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .virtualizationService-style {
    padding: 30% 32%;
  }
  .virtualizationService-banner {
    height: 500px;
  }
}
