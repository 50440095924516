.solutionrehat-banner {
  background: url(../assets/banners/RedHat\ Solutions.jpg);
  height: 700px;
  background-size: cover;
}

.redHatDecription-image {
  background: url(../assets/banners/redhatintro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.solutionstyle-redhat {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 24% 14%;
}

@media (max-width: 400px) {
  .solutionstyle-redhat {
    font-size: 20px;
    padding: 40% 14%;
  }
  .solutionrehat-banner {
    height: 300px;
  }
  .redHatDecription-image {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .solutionstyle-redhat {
    font-size: 20px;
    padding: 28% 5%;
  }
  .solutionrehat-banner {
    height: 300px;
  }
  .redHatDecription-image {
    height: 230px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .solutionstyle-redhat {
    font-size: 24px;
    padding: 30% 20%;
  }
  .solutionrehat-banner {
    height: 400px;
  }
  .redHatDecription-image {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .solutionstyle-redhat {
    font-size: 30px;
    padding: 30% 20%;
  }
  .solutionrehat-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .solutionstyle-redhat {
    font-size: 30px;
    padding: 30% 20%;
  }
  .solutionrehat-banner {
    height: 500px;
  }
}
