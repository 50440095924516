.moblieservice-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 24% 30%;
}
.moblieservice-banner {
  background: url(../assets/banners/Server\ Virtualization.jpg);
  background-size: cover;
  height: 700px;
}
.moblieservice-image2{
  background: url(../assets/banners/deviceEnrole.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.moblieservice-image3{
  background: url(../assets/banners/configuration.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.moblieservice-image4{
  background: url(../assets/banners/moblieSecurity.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .moblieservice-style {
    font-size: 20px;
    padding: 40% 14%;
  }
  .moblieservice-banner {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .moblieservice-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .moblieservice-banner {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .moblieservice-style {
    font-size: 24px;
    padding: 30% 26%;
  }
  .moblieservice-banner {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .moblieservice-style {
    font-size: 30px;
    padding: 30% 23%;
  }
  .moblieservice-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .moblieservice-style {
    padding: 26% 26%;
  }
  .moblieservice-banner {
    height: 500px;
  }
}
