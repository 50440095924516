.FAQs-image {
  /* background: url(../assets/FAQs.jpg); */
  background: url(../assets/FAQs\ -1.jpg);
  height: 700px;
  background-size: cover;
}
.faq-text-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 22% 32%;
  margin: 0% 0%;
}
@media (max-width: 400px) {
  .faq-text-style {
    color: rgb(255 255 255);
    font-size: 20px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 46% 5%;
    margin: 0% 0%;
  }
  .FAQs-image {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .faq-text-style {
    color: rgb(255 255 255);
    font-size: 24px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 36% 12%;
    margin: 0% 0%;
  }
  .FAQs-image {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .faq-text-style {
    padding: 36% 18%;
  }
  .FAQs-image {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .faq-text-style {
    padding: 36% 18%;
  }
  .FAQs-image {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .faq-text-style {
    padding: 36% 28%;
  }
  .FAQs-image {
    height: 600px;
  }
}
