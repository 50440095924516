.homeintro-image {
  background: url(../assets/drive-001/home-intro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}

/* service */
.service-bar {
  background-color: #2085a0;
  color: white;
  padding: 2% 0;
}
.service-bar button {
  font-size: 26px;
}

/* why us */
.whyUs-layer {
  background-color: #00235a;
}
.whyUs-header {
  text-align: center;
  color: white;
  font-weight: 500;
  padding: 20px 0px;
}
.whyUs-icon {
  color: white;
  font-size: 75px;
  padding: 10px 0px;
}
/* getInTouch */
.getInTouch-layer {
  padding: 2%;
}
.intro-style {
  padding: 2% 0%;
  text-align: left;
}
/* services-card-css */

.service-layout {
  background-color: #eeeeee;
  width: 100%;
  float: left;
  padding: 25px 0;
}
.service-layout .container {
  padding-top: 2%;
  padding-bottom: 2%;
}
.services-card {
  height: 200px;
  background: #5241c8;

  border-radius: 10px !important;
  padding: 30px 20px;
}
.services-card h5 {
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.services-colorcard {
  height: 200px;
  background: #2e7c78;
  border-radius: 10px;
  padding: 30px 28px;
}

.services-colorcard h5 {
  color: white;
  font-weight: 600;
}

@media only screen and (max-width: 576px) {
  .intro-style {
    padding: 5% 0%;
  }

  .brand-container {
    margin: 5px;
  }

  .service-layout .container .row {
    margin: 0 3%;
  }
}
