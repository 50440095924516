.container-lxf {
  overflow: auto;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: left;
  border: 2px groove #dee2e6;
  /* background-color: #314755; */
  background-color: #000000;
  padding-left: 100px;
  padding-right: 100px;
  color: rgb(255, 255, 255);
}

.logo-style-2 {
  max-height: 120px;
}
.para-style li a {
  color: rgb(255, 255, 255);
  font-family: Helvetica, arial, sans-serif;
  line-height: 2;
  font-size: 18px;
}
.para-style li a:hover {
  color: #f27931;
}
.para-style p {
  height: 30px;
  margin-top: 20px;
  margin-left: -10px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-family: Helvetica, arial, sans-serif;
}
.arrow-style1 {
  color: rgb(255, 255, 255) !important;
  background-color: #fc4c4c !important;
  border: 10px solid #fc4c4c !important;
  border-radius: 20px !important;
  margin-left: 100% !important;
  margin-bottom: 45%;
  cursor: pointer;
}

.copyRights {
  color: rgb(255, 255, 255);
  padding: 20px 20px;
  border-top: 1px solid rgba(255, 255, 225, 0.6);
  text-align: center;
  background-color: #000000;
}

@media (max-width: 576px) {
  .align-style {
    margin-left: 10%;
    margin-top: 10%;
  }
  .container-lxf {
    padding-left: 10px;
    padding-right: 50px;
  }

  .arrow-style1 {
    margin-bottom: 5%;
  }
}
