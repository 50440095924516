.monitoringMangaement-banner {
  background: url(../assets/banners/Infrastructure\ Monitoring1.jpg);
  background-size: cover;
  height: 700px;
}
.monitoringMangaement-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 18% 34%;
}
.monitoringMangaement-image1 {
  background: url(../assets/banners/moniotring1.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.monitoringMangaement-image2 {
  background: url(../assets/banners/moniotring2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.monitoringMangaement-image3 {
  background: url(../assets/banners/moniotring3.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .monitoringMangaement-style {
    font-size: 20px;
    padding: 40% 14%;
  }
  .monitoringMangaement-banner {
    height: 300px;
  }
  .monitoringMangaement-image2 {
    height: 200px;
  }
  .monitoringMangaement-image3 {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .monitoringMangaement-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .monitoringMangaement-banner {
    height: 300px;
  }
  .monitoringMangaement-image2 {
    height: 230px;
  }
  .monitoringMangaement-image3 {
    height: 230px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .monitoringMangaement-style {
    font-size: 24px;
    padding: 30% 26%;
  }
  .monitoringMangaement-banner {
    height: 400px;
  }
  .monitoringMangaement-image3 {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .monitoringMangaement-style {
    font-size: 30px;
    padding: 30% 30%;
  }
  .monitoringMangaement-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .monitoringMangaement-style {
    padding: 30% 32%;
  }
  .monitoringMangaement-banner {
    height: 500px;
  }
}
