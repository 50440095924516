.service-operation-text {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
  position: absolute;
  animation: fadeInText 5s ease forwards;
  transform: translate(13%, 30%);
  font-family: Helvetica, arial, sans-serif;
  margin: -31% 20%;
}
.operationSubpport-banner {
  background: url(../assets/banners/Network\ Services2.jpg);
  background-size: cover;
  height: 700px;
}
.operationSubpport-image1{
  background: url(../assets/banners/operationSupport1.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.operationSubpport-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 23% 33%;
  text-align: right;
}
.service-text-container3 {
  padding: 0% 0%;
}
.keyFactors-layout{
  background-color: #eeeeee;
    width: 100%;
    float: left;
    padding: 25px 0;
}
.process-row {
  font-family: Helvetica, arial, sans-serif;
  display: flex;
  justify-content: center;
  /* -webkit-box-orient: horizontal;
  -webkit-box-direction: normal; */
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.traineeship3 .activity {
  display: flex;
  width: 500px;
  margin: 15px 10px 10px;
  background-color: #242835;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}
.animate-from-bottom__6 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.animate-from-bottom__7 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.traineeship3 .activity .relative-block {
  width: 100%;
  min-height: 250px;
}
@-webkit-keyframes from-left {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes from-left {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.relative-block {
  position: relative;
}

.traineeship3 .inactive {
  padding: 20px;
}
.traineeship3 .inactive .title {
  padding: 20% 0%;
  border-bottom: 3px solid #f5ca2a;
  padding-bottom: 14px;
  /* width: 210px; */
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.traineeship3 .activity .active {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  padding: 25px 30px 50px;
}
.traineeship3 .activity .active .title {
  color: #1f1f1f;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  line-height: 32px;
}
.traineeship3 .activity .active .sub-title {
  margin-top: 10px;
  color: #000;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}
.traineeship3 .activity .active .ul {
  margin-bottom: 45px;
  margin-top: 10px;
}
.traineeship3 .activity .active .li {
  display: flex;
  margin-left: 30px;
  color: #616161;
  font-size: 16px;
  line-height: 26px;
}
.traineeship3 .activity .active .li:before {
  position: absolute;
  content: "";
  color: #e2b921;
  font-family: Helvetica, arial, sans-serif;
  display: inline-block;
  margin-left: -1.6em;
  width: 22px;
}
.traineeship3 .activity:hover {
  width: 558px;
  background-color: #fff;
}
.traineeship3 .activity:hover .inactive {
  display: none;
}
.traineeship3 .activity:hover .active {
  display: flex;
  width: 400px;
}
.traineeship3 .activity:hover .active li {
  font-size: 20px;
  font-weight: 500;
  line-height: 2rem;
}
@media (max-width: 576px) {
  .traineeship3 .activity {
    width: 93%;
  }
  .traineeship3 .activity:hover {
    width: 350px;
    background-color: #fff;
  }
  .traineeship3 .activity:hover .active {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .operationSubpport-style {
    font-size: 20px;
    padding: 40% 14%;
  }
  .operationSubpport-banner {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .operationSubpport-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .operationSubpport-banner {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .operationSubpport-style {
    font-size: 24px;
    padding: 30% 26%;
  }
  .operationSubpport-banner {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .operationSubpport-style {
    font-size: 30px;
    padding: 30% 30%;
  }
  .operationSubpport-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .operationSubpport-style {
    padding: 30% 32%;
  }
  .operationSubpport-banner {
    height: 500px;
  }
}
