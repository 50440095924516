.container {
  /* min-height: 100%; */
  /* padding: 30px;
      font-family: 'PT Sans'; */
  /* text-align: center; */
}

.card {
  background-color: white;
  border-color: #2e7c78;
  border-radius: inherit;
}
.card-accordion {
  /* background-color: white; */
  background: linear-gradient(to right, #0c1318 0%, #180033 51%, #004742 100%);
  padding: 4%;
}
.btn:hover {
  background: #1569a8;
  color: white;
  transition: 0.5s;
}

.accordion {
  margin: 0 auto;
  /* width: 80%; */
  
}
.accordion .card {
  /* box-shadow: 0 5px 15px 0 rgba(68, 68, 68, 0.1); */
  margin-bottom: 20px;

}

.accordion .title {
  padding: 20px 30px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  color: black;
  position: relative;
  font-size: 10px;
  /* background-image: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 120%
    ) !important; */
  margin-bottom: -1px;
  /* border: 1px solid ; */
  text-align: left;
  display: flex;
  font-family: Helvetica, arial, sans-serif;
}

.accordion .title:after {
  content: "+";
  font-size: 24px;
  color: #000;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  right: 30px;
  font-family: Helvetica, arial, sans-serif;
}

.accordion .title.is-expanded {
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  /* background: rgb(9, 18, 66); */
  display: flex;
}

.accordion .title.is-expanded:after {
  content: "-";
  transform: rotate(-360deg);
}

.accordion .content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s;
  margin: 0;
  padding: 0 30px;
  border: solid 1px #eeeeee;
  border-top: 0;
  background: #e8f4fc;
  color: #fff;
}

.accordion .content p {
  padding: 15px 0;
  margin: 0;
  opacity: 0;
  transition: 0.5s;
  color: black !important;
  white-space: pre-line;
}

.accordion .content.is-expanded {
  max-height: 150px;
  overflow: hidden;
}

.accordion .content.is-expanded p {
  opacity: 1;
  text-align: left !important;
}

.accordion:after {
  width: 100%;
  height: 10px;
  display: block;
  /* background: #0e4671; */
  content: "";
}

.accordion-text {
  font-size: 18px !important;
  align-self: center !important;
  padding: 0 30px !important;
  /* font-family: "Roboto"; */
  color: #000;
  font-family: Helvetica, arial, sans-serif;
}
