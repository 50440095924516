.flex_space {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

a h3 {
  font-size: 17px;
  padding: 7px 12px;
  color: white;
  font-family: Helvetica, arial, sans-serif;
}
.logo {
  margin-left: 0%;
  height: 80px;
}

header {
  height: 13vh;

  padding: 0px 20px;
}
header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  width: 100%;
  padding: 0px 3rem;
  background-color: #000000;
  position: fixed;
  z-index: 5;
  line-height: 10vh;
}
header div,
nav {
  display: flex;
}
.container-8dw {
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  background-color: #b7b7b7e6;
}

header img {
  margin: 10px 2px;
  height: 65px;
}

nav li a {
  color: #ffffff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 18px;
  font-family: Helvetica, arial, sans-serif;
  cursor: pointer;
}
.link-style {
  margin: 0px 20px;
  color: #ffffff;
  transition: 0.5s;
  /* font-weight: 500; */
  font-size: 18px;
  /* font-family: "Roboto"; */
  font-family: Helvetica, arial, sans-serif;
  cursor: pointer;
  text-transform: uppercase;
}
.link-style h3 {
  text-transform: uppercase;
}

nav li a:hover {
  color: #f27931 !important;
}
.header-bottom {
  border-bottom: 2px solid black;
}

header i {
  margin: 0 20px;
}
.header-style-top {
  color: #fff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 17px;
  font-family: Helvetica, arial, sans-serif;
  cursor: pointer;
}
.header-style-top:hover {
  color: #f27931 !important;
}
.header-style-top.active {
  color: #f27931 !important;
}

.dropdown-toggle::after {
  font-size: 24px;
  margin: 1px 1px -8px -13px;
  white-space: nowrap;
  background: none;
  border: none;
  color: white;
}
.dropdown-menu {
  background-color: #ffffff !important;
}
.subdropdown-text {
  position: absolute;
  background: transparent;
  color: white;
  margin-top: 0px;
  z-index: 1;
  font-weight: 500;
  font-size: 18px;
}
.subdropdown-text .subdropdown1 {
  padding: 2% 0px;
  background: white;
  color: #000;
  z-index: 1;
  font-weight: 500;
  font-size: 18px;
}
.subdropdown-text .subdropdown1:hover {
  color: #000;
  z-index: 1;
  font-weight: 500;
  font-size: 18px;
}
.subdropdown-text .subdropdown1.subdropdown2 {
  padding: 2% 0px;
  background: white;
  color: #000;
  z-index: 1;
}

.subdropdown1 .dropdown-menu {
  position: static !important;
}

.subdropdown2 .dropdown-menu {
  left: 0 !important;
  right: auto !important;
}
.dropdown-item {
  font-size: 16px;
}
.dropdown-item:hover {
  color: white !important;
  background: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
}

title {
  margin: 0px 20px;
  color: #ffffff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 18px;
  font-family: Helvetica, arial, sans-serif;
  cursor: pointer;
}
.custom-btn {
  color: rgb(0, 0, 0);
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0px 10px;
  background-color: transparent;
}
.custom-btn :hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: white !important;
  width: 102% !important;
  margin-left: -1%;
  height: 100% !important;
  box-shadow: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 100%
    )
    10px 10px 20px 10px;
}

.dropdown-text2:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: white !important;
  width: 102% !important;
  margin-left: -1%;
  height: 100% !important;
  box-shadow: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 100%
    )
    10px 10px 20px 10px;
}

.dropdown-text {
  color: white;
  font-size: 14px !important;
  font-weight: 500;
  margin: 0px 10px;
}
.subMenu {
  position: absolute;
  background: white;
  display: none;
  margin-left: -20px;
  margin-top: 0px;
  z-index: 1;
  border-radius: 10px;
}
.subMenu.show {
  display: block;
  background: white;
}
.subMenu > a {
  color: #000 !important;
  margin: 0;
  padding: 10px;
  margin-left: -24px !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  z-index: 1;
}

.subMenu > a:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: white !important;
  width: 129% !important;
  height: 100% !important;
  margin-left: -32px !important;
  box-shadow: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 100%
    )
    10px 10px 20px 10px;
  border-radius: 10px;
}
.submenu {
  position: absolute;
  background: white;
  display: none;
  margin-left: -63px;
  margin-top: 0px;
  z-index: 1;
  border-radius: 10px;
}
.submenu.show {
  display: block;
  background: white;
}
.submenu > a {
  color: #000 !important;
  margin: 0;
  padding: 10px;
  margin-left: -32px !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  z-index: 1;
}

.submenu > a:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: white !important;
  width: 110% !important;
  height: 100% !important;
  box-shadow: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 100%
    )
    10px 10px 20px 10px;
  border-radius: 10px;
}

.subMenu2 {
  position: absolute;
  background: white;
  display: none;
  margin-left: 0px;
  margin-top: 0px;
  z-index: 1;
  border-radius: 10px;
}
.subMenu2.show {
  display: block;
  background: white;
}
.subMenu2 > a {
  color: #000 !important;
  margin: 0;
  padding: 10px;
  margin-left: -24px !important;
  text-decoration: none !important;
  white-space: nowrap !important;
  z-index: 1;
}

.subMenu2 > a:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: white !important;
  width: 167% !important;
  height: 100% !important;
  margin-left: -32px !important;
  box-shadow: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 100%
    )
    10px 10px 20px 10px;
  border-radius: 10px;
}
.submenu,
.subMenu3 {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  list-style-type: none;
}

.submenu.show,
.subMenu3.show {
  display: block;
}

.subMenu3 {
  right: 100%;
  top: 0;
  border-radius: 10px;
}
.subMenu4 {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  list-style-type: none;
}

.submenu.show,
.subMenu4.show {
  display: block;
}

.submenu {
  left: 0;
  top: 100%;
  border-radius: 10px;
}

.subMenu4 {
  right: 100%;
  top: 35%;
  border-radius: 10px;
}

.nav-link-syc2 {
  margin-left: -33px;
  display: block;
  padding: 10px 16px;
  text-decoration: none;
  color: black;
}

.nav-link-syc2:hover {
  background-image: linear-gradient(
    to right,
    rgb(9, 18, 66) 0%,
    #5472d2 50%,
    #00c1cf 100%
  ) !important;
  color: white !important;
  border-radius: 10px;
}

.nav-link-syc {
  margin-top: 0px !important;
  color: #222;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.link-lkj {
  color: #fff;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  font-family: Helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  transition: border-color 0.2s;
}

a {
  text-decoration: none;
}
.hide-sticky {
  display: none;
}
.rounded-pill-i {
  width: 90px;
  border-radius: 10px !important;
  height: 70px;
  width: 45px;
}
/* //tooltip */
.tooltip-inner {
  background-color: #075d77;
  color: #ffffff;
}

.btn {
  background: none !important;
  border: none !important;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
  outline: none;
  padding: 5px 10px;
}
@media (max-width: 400px) {
  header img {
    margin: 10px 0;
    max-width: 230px;
    height: 65px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  header img {
    margin: 10px 0;
    max-width: 280px;
    height: 65px;
  }
}
@media (max-width: 576px) {
  .input-r3 {
    margin-left: -18%;
  }
  .input-r4 {
    margin-left: -18%;
  }
  .Dropdown.Menu {
    --bs-dropdown-bg: none;
  }

  .dropdown-padding {
    margin-left: -8%;
    margin-bottom: -20px;
  }

  .toggler-style {
    margin-left: 31%;
  }
  header button {
    padding: 1px 0%;
    margin-left: 0%;
    background: black;
    color: white;
    border: none;
    height: 80px;
  }

  .toggler-style {
    margin-left: 1%;
  }
  nav a {
    text-decoration: none;
  }
  .dropdown-style {
    display: block;
    background-image: white !important;
  }
  .dropdown-style > a {
    color: #ffffff !important;
    margin: 0;
    padding: 10px;
    margin-left: -4px !important;
    text-decoration: none !important;
    white-space: nowrap !important;
  }
  .dropdown-style > a:hover {
    background-image: linear-gradient(
      to right,
      rgb(9, 18, 66) 0%,
      #5472d2 50%,
      #00c1cf 100%
    ) !important;
    color: rgb(0, 0, 0) !important;
    width: 128% !important;
    height: 100% !important;
    box-shadow: linear-gradient(
        to right,
        rgb(9, 18, 66) 0%,
        #5472d2 50%,
        #00c1cf 100%
      )
      10px 10px 20px 10px;
  }
  .dropdown-text {
    color: white;
    font-size: 18px !important;
    font-weight: 500;
    margin: 0px 10px;
    font-weight: 500;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .dropdown-text {
    color: white;
    font-size: 18px !important;
    font-weight: 500;
    margin: 0px 10px;
    font-weight: 500;
  }
  header button {
    margin-top: -2%;
    padding: 0px 7%;
    color: white;
    border: none;
    margin-left: 20%;
    background: black;
  }
  nav a {
    font-size: 0.2rem;
  }
}

@media only screen and (max-width: 768px) {
  header {
    position: absolute !important;
  }
  .subdropdown-text {
    position: absolute;
    background: transparent;
    color: white;
    margin-left: -2px;
    margin-top: 0px;
    z-index: 1;
    font-weight: 500;
    font-size: 18px;
  }
  .link-lkj {
    margin-left: -13%;
    font-weight: 500;
  }
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  header nav {
    /* position: fixed; */
    display: none;
  }
  a h3 {
    font-size: 20px;
    padding: 0px 15px;
    font-weight: 700;
    font-family: Helvetica, arial, sans-serif;
  }
  header li a {
    color: white;
    width: 100%;
    transition: 0.5s;
  }
  nav li a:hover {
    color: #f27931;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    width: 100%;
    padding: 0px 3rem;
    background-color: #000000;
    position: fixed;
    z-index: 5;
  }
  /* nav li a {
    color: rgb(255, 255, 255);
    transition: 0.5s;
    font-weight: 700;
  } */
  header i {
    color: white;
  }

  .menu-ezq {
    display: none;
  }

  .mobile-menu-visible {
    display: block !important;
    text-align: left;
    padding: 10px 40px;
    line-height: 2.5;
    margin-top: 5%;
  }
  .toggler-hidden {
    display: none !important;
  }

  .hidden-tooltip .tooltip {
    display: none !important;
  }

  .toggler-style {
    margin-left: 31%;
  }
  nav a {
    font-size: 0.5rem;
  }
  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .dropdown-style {
    display: block;
    background: white !important;
  }
  .dropdown-style > a {
    color: #000 !important;
    margin: 0;
    padding: 10px;
    margin-left: -4px !important;
    text-decoration: none !important;
    white-space: nowrap !important;
  }
  .dropdown-style > a:hover {
    background: white;
    color: white !important;
    width: 108% !important;
    height: 100% !important;
    box-shadow: linear-gradient(
        to right,
        rgb(9, 18, 66) 0%,
        #5472d2 50%,
        #00c1cf 100%
      )
      10px 10px 20px 10px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  header img {
    margin: 15px 0;
    width: 100%;
    height: 50px;
  }

  nav a {
    margin: 0 0.2rem;
  }
  nav li a {
    padding: 0% 0%;
    margin: 0px 0px;
    font-size: 18px;
  }

  .header-style-top {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500;
  }
  a h3 {
    font-size: 16px;
    padding: 7px 5px;
    font-weight: 500;
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  nav li a {
    margin: 0px 5px;
  }
  a h3 {
    font-size: 16px;
    padding: 7px 10px;
    font-weight: 500;
    color: white;
    white-space: nowrap;
    font-family: Helvetica, arial, sans-serif;
  }
  .header-style-top {
    font-size: 16px;
  }

  header img {
    margin: 15px 0;
    width: 100%;
    height: 50px;
  }
  nav li a {
    margin: 0px 0px;
    font-size: 14px;
  }

  .header-style-top {
    font-size: 16px;
    white-space: nowrap;
    font-weight: 500;
    padding: 7px 15px;
  }
}
