.video-image {
  background: url(../assets/banners/videosBanner.jpg);
  background-size: cover;
  height: 500px;
}
.video-bannerStyle {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 20% 48%;
  margin: 0% 0%;
}
.card-video {
  position: relative;
  margin: auto;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  /* height: 300px; */
  width: 100%;
  text-align: center;
  opacity: 20;
}
.card-content p {
  padding-top: 2%;
  font-weight: 500;
  text-align: center;
}

.card-container1 {
  position: relative;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 5px 45px #4a50561a;
  border-radius: 30px;
  padding: 24px 0px;
  opacity: 1;
  transition: 0.3s all;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-image {
  position: relative;
  margin: auto;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  height: 200px;
  width: 100%;
  text-align: center;
  opacity: 20;
}
.card-container1.button-34 {
  margin: 0% 25%;
}
@media (max-width: 400px) {
  .video-bannerStyle {
    font-size: 20px;
    padding: 40% 40%;
  }
  .video-image {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .video-bannerStyle {
    font-size: 20px;
    padding: 40% 45%;
  }
  .video-image {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .video-bannerStyle {
    font-size: 24px;
    padding: 30% 45%;
  }
  .video-image {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .video-bannerStyle {
    font-size: 30px;
    padding: 30% 45%;
  }
  .video-image {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .video-bannerStyle {
    padding: 30% 50%;
  }
  .video-image {
    height: 500px;
  }
}
