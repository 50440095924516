.contact-image {
  background: url(../assets/banners/Contact\ us.jpg);
  height: 700px;
  background-size: cover;
  margin-top: -1%;
}

.contactBanner-style {
  color: white;
  font-size: 35px;
  font-weight: 600;
  position: absolute;
  animation: fadeInText 5s ease forwards;
  transform: translate(13%, 30%);
  font-family: Helvetica, arial, sans-serif;
  margin: -32% 33%;
}
.getIntouch {
  background-color: #ffffff;
  padding: 4% 0px;
}
.getIntouch h1 {
  text-align: left;
}

.font-icon-style {
  margin-bottom: 0px;
  font-size: 30px;
  margin-top: 14px !important;
}
.custom-card-contact {
  cursor: pointer;
  margin: 1%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-card-contact h5 {
  text-align: center;
  font-size: 24px;
}

@media (max-width: 576px) {
  .custom-card-contact {
    margin: 5%;
  }
  .video-banner {
    overflow: hidden;
  }
  .contactBanner-style {
    font-size: 30px;
    margin: -33% 24%;
  }

  .contact-image {
    height: 255px;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .contact-image {
    height: 500px;
  }
}
@media (min-width: 768px) and (max-width: 768px) {
  .contact-image {
    height: 500px;
  }
}
