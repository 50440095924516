.aboutus-banner {
  background: url(../assets/services-banner2.jpg);

  background-size: cover;
  height: 400px;
}
.aboutus-bannerimage {
  background: url(../assets/banners/Infrastructure\ Monitoring2.jpg);
  background-size: cover;
  height: 700px;
}
.aboutus-paraStyle {
  text-align: left;
  padding: 20% 0%;
}
.aboutus-header-style {
  padding: 4% 0;
  text-align: left;
}
.aboutUs-banner-text {
  color: white;
  font-size: 35px;
  font-weight: 600;
  position: absolute;
  animation: fadeInText 5s ease forwards;
  transform: translate(13%, 30%);
  font-family: Helvetica, arial, sans-serif;
  margin: -26% 10%;
}
@keyframes fadeInText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.ourStory-image {
  background: url(../assets/strory@.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.ourStory-image2 {
  background: url(../assets/banners/story2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.ourStory-image3 {
  background: url(../assets/banners/story3.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.ourStory-image4 {
  background: url(../assets/banners/story4.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.infrastucture-image {
  background: url(../assets/banners/coreInfra.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.aboutusIntro-image {
  background: url(../assets/banners/Cloud\ Computing.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}

.ourMission {
  margin-top: 2%;
  background-color: #00235a;
  padding: 2% 0;
}
.ourMission h2 {
  color: white;
}
.ourMission p {
  color: white;
}
.ourTeam {
  background-color: #00235a;
  padding: 2% 0;
}
.ourTeam h2 {
  color: white;
}
.ourTeam p {
  color: white;
}
/* ourvalues */

.ourvalues {
  background-color: #eeeeee;
  /* min-height: 100vh; */
  width: 100%;
  float: left;
  padding: 25px 0;
}

.ourvalues .content {
  float: left;
  width: 100%;
}

.ourvalues .content .box {
  float: left;
  padding: 15px;
}
.ourvalues .content .box .inner {
  padding: 30px;
  text-align: center;
  position: relative;
  background: #2e7c78;
  width: 250px !important;
  height: 370px !important;
  /* margin-bottom: 2%; */
  color: white;
}
/* .ourvalues .content .box .inner h3 {
  color: white;
}
.ourvalues .content .box .inner h3:hover {
  color: rgb(0, 0, 0);
} */
.ourvalues .content .box .inner p {
  font-size: 18px !important;
}
.ourvalues .content .box .inner:hover {
  background: #fff;
  color: rgb(0, 0, 0);
}
.ourvalues .content .box .inner::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  box-sizing: border-box;
  /* height: 0; */
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  transition: all 0.8s ease;
}

.ourvalues .content .box .inner::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  box-sizing: border-box;
  /* height:0; */
  border-top: 3px solid transparent;
  border-right: 3px solid transparent;
  transition: all 0.8s ease;
}

.ourvalues .content .box:hover .inner::after,
.ourvalues .content .box:hover .inner::before {
  border-color: #ff7e5d;
  width: 100%;
  height: 100%;
}

@media (max-width: 400px) {
  .aboutus-bannerimage {
    height: 300px;
  }
  .aboutUs-banner-text {
    margin-left: -16%;
    font-size: 22px;
    font-weight: 500;
    /* padding: 7% 20%; */
    margin: -50% 18% 0px -8%;
    word-break: break-word;
  }

  .video-style {
    width: 150%;
  }
  .ourvalues .content .row {
    margin: 0 8%;
  }
  .ourStory-image {
    height: 220px;
  }
  .ourStory-image2 {
    height: 220px;
  }
  .ourStory-image3 {
    height: 220px;
  }
  .ourStory-image4 {
    height: 220px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .aboutus-bannerimage {
    height: 300px;
  }
  .aboutUs-banner-text {
    font-size: 24px;
    font-weight: 500;
    margin: -50% 18% 0px -8%;
    word-break: break-word;
  }
  
  .ourvalues .content .row {
    margin: 0 8%;
  }
  .ourvalues .content .box .inner {
    padding: 30px;
    text-align: center;
    position: relative;
    background: #2e7c78;
    width: 100% !important;
    height: 100% !important;
    /* margin-bottom: 2%; */
    color: white;
  }
  .ourStory-image {
    height: 220px;
  }
  .ourStory-image2 {
    height: 220px;
  }
  .ourStory-image3 {
    height: 220px;
  }
  .ourStory-image4 {
    height: 220px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .aboutus-bannerimage {
    height: 400px;
  }
  .aboutUs-banner-text {
    font-size: 24px;
    font-weight: 500;
    margin: -30% 0% 0px -3%;
    word-break: break-word;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .aboutus-bannerimage {
    height: 500px;
  }
  .aboutUs-banner-text {
    font-size: 35px;
    font-weight: 500;
    margin: -29% 0% 0px -5%;
    word-break: break-word;
  }
  .ourStory-image {
    height: 220px;
  }
  .ourStory-image2 {
    height: 220px;
  }
  .ourStory-image3 {
    height: 220px;
  }
  .ourStory-image4 {
    height: 220px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .aboutus-bannerimage {
    height: 500px;
  }
  .ourvalues .content .box .inner {
    width: 223px !important;
    height: 400px !important;
  }
  .aboutUs-banner-text {
    font-size: 35px;
    font-weight: 500;
    margin: -30% 7%;
    word-break: break-word;
  }
}
