.cloudService-banner {
  background: url(../assets/banners/Cloud\ Services.jpg);
  background-size: cover;
  height: 700px;
}
.cloudService-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 30% 38%;
}
.cloudServiceIntro-image1 {
  background: url(../assets/banners/cloudServiceIntro1.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.cloudServiceIntro-image2 {
  background: url(../assets/banners/cloudServiceIntro2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.cloudServiceIntro-image3 {
  background: url(../assets/banners/cloudServiceIntro3.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.cloudServiceIntro-image4 {
  background: url(../assets/banners/cloudServiceIntro4.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.cloudservice-layer {
  background-color: #00235a;
}
@media (max-width: 400px) {
  .cloudService-style {
    font-size: 20px;
    padding: 40% 14%;
  }
  .cloudService-banner {
    height: 300px;
  }
  .cloudServiceIntro-image2 {
    height: 230px;
  }
  .cloudServiceIntro-image3 {
    height: 250px;
  }
  .cloudServiceIntro-image4 {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .cloudService-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .cloudService-banner {
    height: 400px;
  }
  .cloudServiceIntro-image2 {
    height: 250px;
  }
  .cloudServiceIntro-image3 {
    height: 250px;
  }
  .cloudServiceIntro-image4 {
    height: 250px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .cloudService-style {
    font-size: 24px;
    padding: 30% 20%;
  }
  .cloudService-banner {
    height: 300px;
  }
  .cloudServiceIntro-image2 {
    height: 250px;
  }
  .cloudServiceIntro-image4 {
    height: 250px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .cloudService-style {
    font-size: 30px;
    padding: 30% 26%;
  }
  .cloudService-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .cloudService-style {
    padding: 30% 32%;
  }
  .cloudService-banner {
    height: 500px;
  }
  .cloudServiceIntro-image3 {
    height: 300px;
  }
}
