.enduser-bannertext {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 24% 26%;
}
.endUser-bannerImage {
  background: url(../assets/banners/endUserBanner.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 700px;
}
.endUser-image {
  /* background: url(../assets/banners/endUserIntro.jpg); */
  background: url(../assets/banners/endUserService.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}

.testimonial-icon {
  background-color: white;
  position: absolute;
  top: 10px;
  left: 90px;
  font-size: 31px;
}
#style-vnoDw.style-vnoDw {
  filter: invert(0%);
  margin: 35%;
}
.carouselArrow {
  display: flex;
  height: 56px;
  margin-top: 10px;
}
.justify-center {
  justify-content: center;
}
.subtitle-aqf {
  min-width: 4px;
  background-image: linear-gradient(to right, #ffb629, #ffda56 45%, #ffd7a6);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
}
.section-bjn {
  text-transdiv: capitalize;
  font-size: 25px;
  line-height: 1.2;
}
.carousel {
  width: 100%;
  height: 300px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 204, 255, 0.2);
}
.carousel .slides {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  width: 50%;
  transition: 1s ease-in-out all;
  height: 100%;
}
.testimonial-container {
  border: 1px solid #eee;
  padding: 45px 30px;
  position: relative;
  transition: 0.35s;
}
.testimonial-container:hover {
  border-color: aqua;
}
.testimonial-slide {
  /* max-width: 600px; */
}
.carousel .slides .slide {
  min-width: 100%;
  min-height: 250px;
  height: auto;
}

#style-KTGA2.style-KTGA2 {
  background-color: #3caa9f;
  position: relative;
}
.row-va2 {
  max-height: 45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  background-color: #ffbe34;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  /* display: flex; */
  top: auto;
  bottom: 0%;
  left: -10%;
  right: 0%;
}
.testimonial-content h3 {
  padding: 5% 20%;
}
.testimonial-content p {
  padding: 0% 20%;
}

.process-row {
  font-family: Helvetica, arial, sans-serif;
  display: flex;
  justify-content: center;
  /* -webkit-box-orient: horizontal;
    -webkit-box-direction: normal; */
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.traineeship2 .activity {
  display: flex;
  width: 500px;
  margin: 15px 10px 10px;
  background-color: #242835;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
}
.animate-from-bottom__6 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.animate-from-bottom__7 {
  -webkit-animation-name: from-left;
  animation-name: from-left;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}

.traineeship2 .activity .relative-block {
  width: 100%;
  min-height: 250px;
}
@-webkit-keyframes from-left {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes from-left {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.relative-block {
  position: relative;
}

.traineeship2 .inactive {
  padding: 20px;
}
.traineeship2 .inactive .title {
  padding: 20% 0%;
  border-bottom: 3px solid #f5ca2a;
  padding-bottom: 14px;
  /* width: 210px; */
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.traineeship2 .activity .active {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  padding: 25px 30px 50px;
}
.traineeship2 .activity .active .title {
  color: #1f1f1f;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  line-height: 32px;
}
.traineeship2 .activity .active .sub-title {
  margin-top: 10px;
  color: #000;
  font-size: 16px;
  line-height: 28px;
  text-align: left;
}
.traineeship2 .activity .active .ul {
  margin-bottom: 45px;
  margin-top: 10px;
}
.traineeship2 .activity .active .li {
  display: flex;
  margin-left: 30px;
  color: #616161;
  font-size: 16px;
  line-height: 26px;
}
.traineeship2 .activity .active .li:before {
  position: absolute;
  content: "";
  color: #e2b921;
  font-family: Helvetica, arial, sans-serif;
  display: inline-block;
  margin-left: -1.6em;
  width: 22px;
}
.traineeship2 .activity:hover {
  width: 558px;
  background-color: #fff;
}
.traineeship2 .activity:hover .inactive {
  display: none;
}
.traineeship2 .activity:hover .active {
  display: flex;
  width: 400px;
}
.traineeship2 .activity:hover .active li {
  font-size: 20px;
  font-weight: 500;
  line-height: 2rem;
}

@media screen and (min-width: 1280px) {
  .row-va2 {
    top: 10px !important;
    bottom: auto !important;
    left: auto !important;
    right: 1% !important;
  }
}

.hid-fp1 {
  display: none;
}

#style-iXr2r.style-iXr2r {
  background-color: #3caa9f;
  position: relative;
}

@media screen and (min-width: 1280px) {
  .row-v6h {
    top: 10px !important;
    bottom: auto;
    left: auto;
    right: 0%;
  }
}

.row-v6h {
  max-height: 45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  background-color: #ffbe34;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: -10%;
}

@media (max-width: 400px) {
  .endUser-bannerImage {
    height: 250px;
  }
  .enduser-bannertext {
    font-size: 20px !important;
    padding: 47% 16% !important;
    text-align: left;
  }

  .traineeship2 .activity:hover {
    width: 350px;
    background-color: #fff;
  }

  .section-kt1 {
    padding-top: 0px;
    padding-bottom: 100px;
    overflow: hidden;
    margin: 0px 0px 0px 0px;
  }
  .carousel {
    height: 350px;
    width: 315px;
  }
  .carousel .slides {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    width: 100%;
    transition: 1s ease-in-out all;
    height: 100%;
  }
  .container-rwt {
    max-width: 338px;
    padding-left: 36px;
    padding-right: -3px;
  }
  .card-aqx {
    padding: 20px;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .traineeship2 .activity:hover .active {
    display: flex;
    width: 100%;
  }
  .traineeship2 .activity {
    width: 93%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .endUser-bannerImage {
    height: 300px;
  }
  .traineeship2 .activity:hover {
    width: 350px;
    background-color: #fff;
  }
  .traineeship2 .activity:hover .active {
    display: flex;
    width: 100%;
  }
  .traineeship2 .activity {
    width: 93%;
  }
  .enduser-bannertext {
    font-size: 20px !important;
    padding: 47% 16% !important;
    text-align: left;
  }
  .section-kt1 {
    padding-top: 100px;
    padding-bottom: 100px;
    overflow: hidden;
    margin: -34px 0px 0px 0px;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .carousel {
    height: 350px;
    width: 350px;
  }
  .carousel .slides {
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .container-rwt {
    max-width: 383px;
    padding-left: 36px;
    padding-right: -3px;
  }
  .card-aqx {
    padding: 30px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .endUser-bannerImage {
    height: 400px;
  }
  .enduser-bannertext {
    font-size: 20px !important;
    padding: 49% 24% !important;
    text-align: left;
  }

  .carousel .slides {
    width: 100%;
    height: 100%;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .container-rwt {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-aqx {
    padding: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .endUser-bannerImage {
    height: 500px;
  }
  .enduser-bannertext {
    font-size: 30px;
    padding: 24% 26% !important;
    text-align: left;
  }
  .carousel .slides {
    width: 100%;
    height: 100%;
  }
  .row-va2 {
    top: 1px;
    bottom: auto;
    left: auto;
    right: 3%;
  }
  .container-rwt {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-aqx {
    padding: 30px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .endUser-bannerImage {
    height: 700px;
  }
  .carousel .slides {
    width: 50%;
    height: 120%;
  }
  .container-rwt {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .card-aqx {
    padding: 70px;
  }
  .enduser-bannertext {
    font-size: 35px;
    padding: 55% 20% !important;
    text-align: left;
  }
}
