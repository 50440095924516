.caseStudies-image {
  background: url(../assets/drive-001/caseStudiesbanner.png);
  background-size: cover;
  height: 500px;
}
@media (max-width: 400px) {
  .caseStudies-image {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .caseStudies-image {
    height: 200px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .caseStudies-image {
    height: 250px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .caseStudies-image {
    height: 350px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .caseStudies-image {
    height: 500px;
  }
}
