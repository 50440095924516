.blog-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 22% 46%;
  margin: 0% 0%;
}
.blog-banneerImage {
  background: url(../assets/banners/Blogs.jpg);
  height: 700px;
  background-size: cover;
}
@keyframes fadeInText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.blog-text-style {
  margin-top: 2%;
  text-align: left;
}
.blog-text-style h4 {
  font-weight: 600;
}
.blog-text-style h5 {
  font-weight: 700;
}
.blog-text-style p {
  font-weight: 500;
}

.card-content {
  padding: 2%;
}
.card-content h3 {
  font-weight: 600;
}

.card-container {
  position: relative;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 5px 45px #4a50561a;
  border-radius: 30px;
  padding: 24px 0px;
  opacity: 1;
  transition: 0.3s all;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-image {
  position: relative;
  margin: auto;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  height: 200px;
  width: 100%;
  text-align: center;
  opacity: 20;
}
.card-container.button-34 {
  margin: 0% 25%;
}

@media (max-width: 576px) {
  .blog-style {
    color: rgb(255 255 255);
    font-size: 24px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 40% 46%;
    margin: 0% 0%;
  }
  .blog-banneerImage {
    height: 275px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .blog-style {
    color: rgb(255 255 255);
    font-size: 24px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 40% 46%;
    margin: 0% 0%;
  }
  .blog-banneerImage {
    height: 500px;
  }
}
