.enduser-bannertext {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 47% 27%;
}
.endUser-banner {
  background: url(../assets/banners/endUser.jpg);
  background-size: cover;
  height: 700px;
}
.endUser-image2 {
  background: url(../assets/banners/endPointDevice.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.endUser-image3 {
  background: url(../assets/banners/applicationVirtualization.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.endUser-image4 {
  background: url(../assets/banners/userExperience.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .endUser-banner {
    height: 300px;
  }
  .endUser-image2 {
    height: 200px;
  }
  .endUser-image3 {
    height: 200px;
  }
  .endUser-image4 {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .endUser-banner {
    height: 300px;
  }
  .endUser-image2 {
    height: 230px;
  }
  .endUser-image3 {
    height: 250px;
  }
  .endUser-image4 {
    height: 250px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .endUser-banner {
    height: 400px;
  }
  .endUser-image2 {
    height: 300px;
  }
  .endUser-image3 {
    height: 250px;
  }
  .endUser-image4 {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .endUser-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .endUser-banner {
    height: 500px;
  }
}
