.networkService-banner {
  background: url(../assets/banners/Network\ Services3.jpg);
  background-size: cover;
  height: 700px;
}
.networkService-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 24% 38%;
}
.network-image {
  background: url(../assets/drive-001/network-1.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.networksubhead2-image {
  background: url(../assets/banners/serverIntro.jpg);

  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.networksubhead3-image {
  background: url(../assets/banners/networksubhead3.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.networksubhead4-image {
  background: url(../assets/banners/networksubhead4.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.col-xs-12.col-sm-12.col-md-6.col-lg-6 h2 {
  margin-bottom: 5%;
}
.keyActivities-layout {
  background-color: #eeeeee;
  width: 100%;
  float: left;
  padding: 25px 0;
}
.keyActivities-style {
  text-align: left;
}
.service-text-container5 {
  padding-top: 2%;
}
.service-text-container5 h2 {
  padding: 2% 0%;
}
.service-text-container6 {
  padding: 0% 2%;
}
#card-area {
  margin: -12% 0;
  padding: 0px 0;
}
.wrapper {
  margin: 10%;
  padding: 10px 10%;
}

.text-content {
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 500;
  padding: 40% 5%;
  background-color: #00235a;
}
.box-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
}
.box-card {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
}

.overlay {
  width: 100%;
  height: 0;
  background: linear-gradient(to right, #0c1318 0%, #180033 51%, #004742 100%);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: height 0.5s;
  color: white;
}
.overlay h3 {
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 20%;
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
  letter-spacing: 2px;
  color: white !important;
}
.overlay p {
  font-size: 24px;
}
.overlay a {
  margin-top: 10px;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  background: #fff;
  border-radius: 50px;
  text-align: center;
  padding: 5px 15px;
}

.box-card:hover .overlay {
  height: 100%;
}
@media (max-width: 576px) {
  .wrapper {
    margin: 10%;
    padding: 10px 5%;
  }
}

@media (max-width: 400px) {
  .networkService-banner {
    height: 300px;
  }
  .networkService-style {
    font-size: 20px;
    padding: 40% 22%;
  }
  .overlay p {
    font-size: 18px;
  }
  .networksubhead3-image {
    height: 230px;
  }
  .service-text-container5 {
    padding: 5% 4%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .networkService-banner {
    height: 300px;
  }
  .networkService-style {
    font-size: 20px;
    padding: 46% 28%;
  }
  .overlay p {
    font-size: 20px;
  }
  .networksubhead3-image {
    height: 300px;
  }
  .service-text-container5 {
    padding: 5% 4%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .networkService-banner {
    height: 400px;
  }
  .networkService-style {
    font-size: 24px;
    padding: 30% 32%;
  }
  .overlay p {
    font-size: 20px;
  }
  .service-text-container5 {
    padding: 5% 4%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .networkService-banner {
    height: 500px;
  }
  .networkService-style {
    font-size: 30px;
    padding: 30% 34%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .networkService-banner {
    height: 500px;
  }
  .networkService-style {
    padding: 29% 36%;
  }
}
