.collaboration-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 28% 34%;
}
.collaboration-banner {
  background: url(../assets/banners/Collaboration\ and\ Communication.jpg);
  background-size: cover;
  height: 700px;
}
.collaboration-image1 {
  background: url(../assets/banners/collaboration1.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.collaboration-image2 {
  background: url(../assets/banners/collaboration2.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.collaboration-image3 {
  background: url(../assets/banners/collaboration3.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .collaboration-style {
    font-size: 20px;
    padding: 40% 14%;
  }
  .collaboration-banner {
    height: 300px;
  }
  .collaboration-image2 {
    height: 200px;
  }
  .collaboration-image3 {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .collaboration-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .collaboration-banner {
    height: 300px;
  }
  .collaboration-image2 {
    height: 230px;
  }
  .collaboration-image3 {
    height: 230px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .collaboration-style {
    font-size: 24px;
    padding: 30% 22%;
  }
  .collaboration-banner {
    height: 400px;
  }
  .collaboration-image2 {
    height: 300px;
  }
  .collaboration-image3 {
    height: 300px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .collaboration-style {
    font-size: 30px;
    padding: 30% 26%;
  }
  .collaboration-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .collaboration-style {
    padding: 26% 24%;
  }
  .collaboration-banner {
    height: 500px;
  }
}
