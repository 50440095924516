.service-text-container {
  padding: 10% 0%;
}
.coreinfra-banner {
  background: url(../assets/banners/coreBanner.jpg);
  background-size: cover;
  height: 700px;
}
.coreinfraIntro-image {
  background: url(../assets/banners/coreintro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 300px;
}
.core-text-container {
  padding: 2% 0%;
}

.coreinfra-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 38% 26%;
}
@media (max-width: 400px) {
  .coreinfra-banner {
    background: url(../assets/banners/coreBanner.jpg);
    background-size: cover;
    height: 250px;
  }
  .service-text-container {
    padding: 10% 6%;
  }
  .background-overlay h4 {
    padding: 2% 4%;
    color: white;
    text-align: center;
  }
  .coreinfra-style {
    font-size: 20px;
    padding: 41% 0% 0% 35%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .coreinfra-banner {
    background: url(../assets/banners/coreBanner.jpg);
    background-size: cover;
    height: 300px;
  }
  .service-text-container {
    padding: 10% 6%;
  }
  .background-overlay h4 {
    padding: 2% 4%;
    color: white;
  }
  .coreinfra-style {
    font-size: 20px;
    padding: 47% 0% 3% 25%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .coreinfra-banner {
    background: url(../assets/banners/coreBanner.jpg);
    background-size: cover;
    height: 500px;
  }
  .coreinfra-style {
    color: rgb(255 255 255);
    font-size: 24px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 38% 26%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .coreinfra-style {
    color: rgb(255 255 255);
    font-size: 28px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 38% 26%;
  }
  .coreinfra-banner {
    background: url(../assets/banners/coreBanner.jpg);
    background-size: cover;
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .coreinfra-style {
    color: rgb(255 255 255);
    font-size: 35px;
    font-weight: 600;
    animation: fadeInText 5s ease forwards;
    font-family: Helvetica, arial, sans-serif;
    padding: 38% 26%;
  }
}
