.whitePapers-image {
  background: url(../assets/drive-001/whitepapers.png);
  background-size: cover;
  height: 500px;
}
.whitePapers-bannerStyle {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 22% 40%;
  margin: 0% 0%;
}
@media (max-width: 400px) {
  .whitePapers-bannerStyle {
    font-size: 20px;
    padding: 40% 32%;
  }
  .whitePapers-image {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .whitePapers-bannerStyle {
    font-size: 20px;
    padding: 40% 35%;
  }
  .whitePapers-image {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .whitePapers-bannerStyle {
    font-size: 24px;
    padding: 30% 35%;
  }
  .whitePapers-image {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .whitePapers-bannerStyle {
    font-size: 30px;
    padding: 30% 38%;
  }
  .whitePapers-image {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .whitePapers-bannerStyle {
    padding: 23% 37%;
  }
  .whitePapers-image {
    height: 500px;
  }
}
