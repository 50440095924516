.governance-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 18% 22%;
}
.governance-banner {
  background: url(../assets/banners/compliance-1.jpg);
  background-size: cover;
  height: 700px;
}
.governance-image1 {
  background: url(../assets/banners/complianceBanner.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.governance-image2 {
  background: url(../assets/banners/ITGovernance.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.governance-image3 {
  background: url(../assets/banners/risk.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
.governance-image4 {
  background: url(../assets/banners/complianceRegulatory.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .governance-style {
    font-size: 20px;
    padding: 46% 14%;
  }
  .governance-banner {
    height: 300px;
  }
  .governance-image2 {
    height: 200px;
  }
  .governance-image4 {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .governance-style {
    font-size: 20px;
    padding: 46% 22%;
  }
  .governance-banner {
    height: 300px;
  }
  .governance-image2 {
    height: 250px;
  }
  .governance-image4 {
    height: 350px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .governance-style {
    font-size: 24px;
    padding: 30% 20%;
  }
  .governance-banner {
    height: 400px;
  }
  .governance-image2 {
    height: 250px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .governance-style {
    font-size: 30px;
    padding: 18% 6%;
  }
  .governance-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .governance-style {
    padding: 20% 20%;
  }
  .governance-banner {
    height: 500px;
  }
}
