.webinar-image {
  background: url(../assets/drive-001/web.jpg);
  background-size: cover;
  height: 700px;
}
.webinar-bannerStyle {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 22% 40%;
  margin: 0% 0%;
}
@media (max-width: 400px) {
  .webinar-bannerStyle{
    font-size: 20px;
    padding: 40% 40%;
  }
  .webinar-image {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .webinar-bannerStyle{
    font-size: 20px;
    padding: 34% 45%;
  }
  .webinar-image {
    height: 300px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .webinar-bannerStyle{
    font-size: 24px;
    padding: 30% 45%;
  }
  .webinar-image {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .webinar-bannerStyle{
    font-size: 30px;
    padding: 30% 45%;
  }
  .webinar-image {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .webinar-bannerStyle{
    padding: 30% 50%;
  }
  .webinar-image {
    height: 500px;
  }
}