.solutionBanner-style {
  color: white;
  font-size: 35px;
  font-weight: 600;
  position: absolute;
  animation: fadeInText 5s ease forwards;
  transform: translate(13%, 30%);
  font-family: Helvetica, arial, sans-serif;
  margin: -30% 28%;
}
.solutionNutanix-style {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 22% 50%;
}
.solutionNutanix-bannerImage {
  background: url(../assets/banners/Nutanix\ Solutions.jpg);
  height: 700px;
  background-size: cover;
}
.solutionNutanix-image {
  background: url(../assets/banners/nutanixIntro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}

@media (max-width: 400px) {
  .solutionNutanix-style {
    font-size: 20px;
    padding: 46% 20%;
  }
  .solutionNutanix-bannerImage {
    height: 300px;
  }
  .solutionNutanix-image {
    height: 300px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .solutionNutanix-style {
    font-size: 20px;
    padding: 50% 20%;
  }
  .solutionNutanix-bannerImage {
    height: 400px;
  }
  .solutionNutanix-image {
    height: 400px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .solutionNutanix-style {
    font-size: 24px;
    padding: 30% 20%;
  }
  .solutionNutanix-bannerImage {
    height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .solutionNutanix-style {
    font-size: 30px;
    padding: 30% 20%;
  }
  .solutionNutanix-bannerImage {
    height: 500px;
  }
  .solutionNutanix-image {
    height: 350px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .solutionNutanix-style {
    padding: 25% 50%;
  }
  .solutionNutanix-bannerImage {
    height: 500px;
  }
}
