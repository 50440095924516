.service-card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
}

.service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0c1318 0%, #180033 51%, #004742 100%);
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}
h3 {
  font-size: 20px;
  text-transform: capitalize;
  margin: 1em 0;
  z-index: 3;
}
p {
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.service-card:hover:after {
  top: 0%;
}

.service-card:hover h3 {
  color: #ffffff;
}
.service-card:hover p {
  color: #f0f0f0;
}

/* //flipcard */
.flip-card {
  perspective: 1000px;
  min-height: 350px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: none;
  min-height: 350px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: darkslategray;
}

.flip-card-front img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 350px;
}
.flip-card-back {
  transform: rotateY(-180deg);
}
.flip-card-back p {
  font-size: 16px;
}
.flip-padding2 p {
  font-size: 18px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-text {
  position: absolute;
  color: white;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 10px;
  border-radius: 5px;
}

.flip-card-back {
  /* background-color: #19a7d1; */
  background: linear-gradient(to right, #0c1318 0%, #180033 51%, #004742 100%);
  color: white;
  font-size: 18px;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding: 8%;
}
.flip-padding {
  padding: 10% !important;
}
.flip-padding2 {
  padding: 12% !important;
}

.flip-card-back .content-container {
  padding: 15px;
  height: 100%;
  overflow-y: auto;
}
