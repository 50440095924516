.service-banneerImage {
  background: url(../assets/services-banner2.jpg);
  height: 600px;
  background-size: cover;
}
.bannerImage1 {
  background: url(../assets/banners/StockCake-Connected\ Digital\ Globe_1720781566.jpg);
  width: 100%;
  height: 620px;
  background-size: cover;
}
.bannerImage2 {
  background-image: url(../assets/drive-001/storage-1.jpg);
  width: 100%;
  height: 600px;
  background-size: cover;
}
.bannerImage3 {
  background: url(../assets/drive-001/banner-3.jpg);
  height: 600px;
  background-size: cover;
}
.bannerImage4 {
  background: url(../assets/drive-001/enduser-banner.jpg);
  height: 600px;
  background-size: cover;
}
.banner-style {
  text-align: right;
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  /* font-family: "Robato"; */
  font-family: Helvetica, arial, sans-serif;
  padding: 16% 25%;
  margin: 0% 0%;
  /* text-shadow: 3px 3px #004aff; */
}
.title-c8g {
  height: 280px;
  width: 400px !important;
}
.title-3 {
  height: 280px;
  width: 400px !important;
}
.title-4 {
  height: 200px;
  width: 400px !important;
}
.slideText1,
.slideText2 {
  background-color: rgba(41, 40, 45, 0.7) !important;
  position: relative !important;
  margin: 1% 10%;
  top: 40%;
}
.slideText1 {
  top: 35% !important;
}
.slideText1 {
  background-color: rgb(41, 40, 45, 0.7) !important;
  position: relative !important;
  top: 30% !important;
  /* float: right !important; */
  margin-left: 65% !important;
  padding: 20px !important;
}
.banner-style2 {
  box-shadow: 0 5px 15px 0 rgba(68, 68, 68, 0.1);
  color: rgb(245, 245, 245);
  font-size: 24px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  /* font-family: "Robato"; */
  font-family: Helvetica, arial, sans-serif;
  padding: 12% 10%;
  margin: 0% 0%;
  text-align: left;
  /* text-shadow: 3px 3px #004aff; */
}
.banner-style3 {
  box-shadow: 0 5px 15px 0 rgba(68, 68, 68, 0.1);
  color: rgb(255 255 255);
  font-size: 24px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  /* font-family: "Robato"; */
  font-family: Helvetica, arial, sans-serif;
  padding: 15% 16%;
  margin: 0% 0%;
  text-align: left;
  /* text-shadow: 3px 3px #004aff; */
}
.banner-style4 {
  box-shadow: 0 5px 15px 0 rgba(68, 68, 68, 0.1);
  color: rgb(255 255 255);
  font-size: 24px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 10% 5%;
  margin: 0% 0%;
  text-align: right;
}
.banner-style-5 {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 21% 32%;
  margin: 0% 0%;
  text-align: left;
}
.banner-style-core {
  color: rgb(255 255 255);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  /* font-family: "Robato"; */
  font-family: Helvetica, arial, sans-serif;
  padding: 16% 32%;
  margin: 0% 0%;
  text-align: left;
}
.service-banner-text {
  top: 0;
  left: 0;
  color: white;
  font-size: 35px;
  font-weight: 600;
  position: absolute;
  animation: fadeInText 5s ease forwards;
  transform: translate(13%, 30%);
  font-family: Helvetica, arial, sans-serif;
  padding: 15% 20% !important;
}
.video-banner {
  overflow: hidden;
}

@media (max-width: 400px) {
  .slideText1,
  .slideText2 {
    background-color: rgba(41, 40, 45, 0.7) !important;
    position: relative !important;
    margin: 0% 5%;
    top: 14%;
  }
  .title-c8g {
    height: 200px;
    width: 201px !important;
  }
  .slideText1 {
    margin-left: 45% !important;
    top: 8% !important;
  }
  .title-3 {
    height: 165px;
    width: 201px !important;
  }
  .title-4 {
    height: 130px;
    width: 201px !important;
  }
  .bannerImage3 {
    margin-top: 20%;
    height: 251px;
    background-size: cover;
  }
  .bannerImage4 {
    margin-top: 20%;
    height: 251px;
    background-size: cover;
  }

  .service-banneerImage {
    height: 302px;
  }

  .banner-style {
    height: 302px;
    font-size: 22px;
    padding: 26% 10%;
  }
  .banner-style2 {
    height: 302px;
    font-size: 16px;
    padding: 6% 5%;
  }
  .banner-style3 {
    height: 302px;
    font-size: 16px;
    padding: 6% 5%;
  }
  .banner-style4 {
    height: 150px;
    font-size: 16px;
    padding: 0% 0%;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .slideText1,
  .slideText2 {
    background-color: rgba(41, 40, 45, 0.7) !important;
    position: relative !important;
    margin: 0% 5%;
    top: 14%;
  }
  .title-c8g {
    height: 200px;
    width: 201px !important;
  }
  .title-3 {
    height: 170px;
    width: 201px !important;
  }
  .title-4 {
    height: 130px;
    width: 201px !important;
  }
  .slideText1 {
    margin-left: 52% !important;
    top: 15% !important;
  }
  .bannerImage3 {
    margin-top: 15%;
    height: 251px;
  }
  .bannerImage4 {
    margin-top: 12%;
    height: 251px;
  }
  .service-banneerImage {
    height: 302px;
  }
  .banner-style {
    height: 302px;
    font-size: 22px;
    padding: 26% 10%;
  }
  .banner-style2 {
    height: 302px;
    font-size: 16px;
    padding: 6% 5%;
  }
  .banner-style3 {
    height: 302px;
    font-size: 16px;
    padding: 6% 5%;
  }
  .banner-style4 {
    height: 150px;
    font-size: 16px;
    padding: 10% 0%;
  }
  .banneer-image3 {
    height: 630px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .slideText1,
  .slideText2 {
    background-color: rgba(41, 40, 45, 0.7) !important;
    position: relative !important;
    margin: 0% 5%;
    top: 30%;
  }
  .title-c8g {
    height: 200px;
    width: 300px !important;
  }
  .title-3 {
    height: 200px;
    width: 300px !important;
  }
  .title-4 {
    height: 150px;
    width: 250px !important;
  }
  .slideText1 {
    margin-left: 58% !important;
    top: 35% !important;
  }
  .bannerImage2 {
    margin-top: 5%;
    height: 351px;
  }
  .bannerImage3 {
    margin-top: 5%;
    height: 351px;
  }
  .banner-style2 {
    height: 302px;
    font-size: 20px;
    padding: 6% 5%;
  }
  .banner-style3 {
    height: 302px;
    font-size: 20px;
    padding: 6% 5%;
  }
  .banner-style4 {
    height: 200px;
    font-size: 20px;
    padding: 6% 2%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bannerImage1 {
    height: 500px;
  }
  .bannerImage2 {
    height: 500px;
  }
  .bannerImage3 {
    height: 500px;
  }
  .bannerImage4 {
    height: 500px;
  }

  .service-banneerImage {
    height: 502px;
  }
  .slideText1,
  .slideText2 {
    background-color: rgba(41, 40, 45, 0.7) !important;
    position: relative !important;
    margin: 0% 5%;
    top: 30%;
  }
  .title-c8g {
    height: 200px;
    width: 300px !important;
  }
  .title-3 {
    height: 200px;
    width: 300px !important;
  }
  .title-4 {
    height: 150px;
    width: 270px !important;
  }
  .slideText1 {
    margin-left: 58% !important;
    top: 35% !important;
  }
  .banner-style2 {
    height: 302px;
    font-size: 20px;
    padding: 6% 5%;
  }
  .banner-style3 {
    height: 302px;
    font-size: 20px;
    padding: 6% 5%;
  }
  .banner-style4 {
    height: 200px;
    font-size: 20px;
    padding: 1% 2%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .service-banneerImage {
    height: 630px;
  }
  .banneer-image3 {
    height: 630px;
  }
}
