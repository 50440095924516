.solutionstyle-vmware {
  color: rgb(0, 0, 0);
  font-size: 35px;
  font-weight: 600;
  animation: fadeInText 5s ease forwards;
  font-family: Helvetica, arial, sans-serif;
  padding: 24% 14%;
}
.solutionVmware-banner {
  background: url(../assets/banners/VMWare\ Solution.jpg);
  height: 700px;
  background-size: cover;
}
.vmwaredecription-image {
  background: url(../assets/banners/vmwareIntro.jpg);
  border-radius: 5%;
  background-size: cover;
  height: 400px;
}
@media (max-width: 400px) {
  .solutionVmware-banner {
    height: 300px;
  }

  .solutionstyle-vmware {
    font-size: 20px;
    padding: 60% 14%;
    color: white;
  }
  .vmwaredecription-image {
    height: 200px;
  }
}
@media (min-width: 400px) and (max-width: 576px) {
  .solutionVmware-banner {
    height: 300px;
  }
  .solutionstyle-vmware {
    font-size: 20px;
    padding: 46% 20%;
    color: white;
  }
  .vmwaredecription-image {
    height: 280px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .solutionBanner-vmware {
    font-size: 24px;
    margin: -30% 6%;
  }
  .solutionVmware-banner {
    height: 400px;
  }
  .vmwaredecription-image {
    height: 320px;
  }
  .solutionstyle-vmware {
    font-size: 30px;
    padding: 46% 20%;
    color: white;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .solutionstyle-vmware {
    font-size: 30px;
    padding: 42% 20%;
    color: white;
  }

  .solutionVmware-banner {
    height: 500px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .solutionBanner-vmware {
    margin: -30% 14%;
  }
  .solutionVmware-banner {
    height: 500px;
  }
}
