.container-description {
  background: #00172d !important;
  padding: 50px 100px;
}
.container-description h2 {
  text-align: left;
  color: white;
  font-weight: 600;
  margin-bottom: 2%;
}
.container-description p {
  font-size: 22px;
  text-align: left;
  color: white;
  font-weight: 600;
}
.description-sideHeader {
  
  font-size: 22px;
  padding: 15px 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background-color: #00235a;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  border-color: white;
  border-width: 3px;
  margin-bottom: 2%;
  border-style: solid;
}

.description-sideHeader.active {
  background-color: #0032ff;
  color: white;
}

.descrption-style {
  color: white;
  padding: 5% 10%;
}
.descrption-style h2 {
  color: white;
  text-align: center;
}
.hr-h1 {
  /* margin: 0 auto 35px auto; */
  margin-bottom: 28px;
  border-style: solid;
  border-width: 3px;
  border-color: #0032ff;
  /* width: 128px; */
}
/* Service slide left */
.slideanimleft {
  visibility: hidden;
}

.slideleft {
  animation-name: slideleft;
  -webkit-animation-name: slideleft;
  -moz-animation-name: slideleft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  -moz-animation-duration: 1.5s;
  /* Make the element visible */
  visibility: visible;
}

@keyframes slideleft {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}

@media (max-width: 576px) {
  .description-sideHeader {
    width: 300px;
  }
  .container-description {
    padding: 50px 10%;
  }
  .container-description p {
    font-size: 20px;
  }
  .descrption-style {
    color: white;
    padding: 15% 0%;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .description-sideHeader {
    width: 300px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .description-sideHeader {
    width: 300px;
  }
}
@media (min-width: 992px) and (max-width: 1040px) {
  .description-sideHeader {
    width: 200px;
    height: 140px;
  }
}
@media (min-width: 1040px) and (max-width: 1200px) {
  .description-sideHeader {
    width: 210px;
    height: 140px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .description-sideHeader {
    width: 250px;
    height: 140px;
  }
}
@media (min-width: 1500px) and (max-width: 2200px) {
  .description-sideHeader {
    width: 300px;
  }
}
